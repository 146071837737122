(function () {
    'use strict';

    angular
      .module('neo.authentication')
      .config(config);

    function config($stateProvider) {
      $stateProvider
        .state('authentication.reset', {
          url: '/reset-password',
          templateUrl: 'authentication/reset-password/reset-password.tpl.html',
          controller: 'ResetPasswordCtrl',
          controllerAs: 'vm',
          params: {
            token: null
          }
        })
        .state('authentication.passwordConfirmation', {
            url: '^/reset?token',
            controller: 'PasswordTokenCtrl',
            controllerAs: 'vm',
            resolve: {
              passwordTokenConfirmation: passwordTokenConfirmation
            }
          }
        )
        .state('authentication.newPassword', {
          url: '^/set-password',
          templateUrl: 'authentication/reset-password/new-password.tpl.html',
          controller: 'NewPasswordCtrl',
          controllerAs: 'vm',
          params: {
            token: null
          }
        });
    }

    function passwordTokenConfirmation(PasswordToken, $stateParams, $q) {
      var deferred = $q.defer();

      PasswordToken.get({token: $stateParams.token},
        function () {
          deferred.resolve('valid');
        },
        function (response) {
          if (response.status === 401) {
            deferred.resolve('invalid');
          } else {
            deferred.reject(response);
          }
        });

      return deferred.promise;
    }
  }()
);
